const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorDarkGray : '#212427',
  colorGray : '#525252',
  colorGray2 : '#434343',
  colorOrange : '#F85427',
  colorOrange2 : '#F95427',
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"acumin-pro-extra-condensed",serif',

  primaryColor: colors.colorOrange,
  fontColor: colors.colorGray2,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;