import { renderRule as code_instagram } from "./code-instagram/render";
import { renderRule as code_subscribe } from "./code-subscribe/render";
import { renderRule as html } from "./html/render";
import { renderRule as imagePanel } from "./imagePanel/render";
import { renderRule as multiColumnPanel2 } from "./multiColumnPanel2/render";
import { renderRule as pageTiles } from "./pageTiles/render";
import { renderRule as panel } from "./panel/render";
import { renderRule as photoGallery } from "./photoGallery/render";
import { renderRule as quotePanel } from "./quotePanel/render";
import { renderRule as sharingIcons } from "./sharingIcons/render";
import { renderRule as videoPanel } from "./videoPanel/render";
import { renderRule as fullPanelGoogleMap } from "./fullPanelGoogleMap/render";
export const items = [
  code_instagram,
  code_subscribe,
  html,
  imagePanel,
  multiColumnPanel2,
  pageTiles,
  panel,
  photoGallery,
  quotePanel,
  sharingIcons,
  videoPanel,
  fullPanelGoogleMap,
];
