import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";
//import { ReactComponent as YourSvg } from "./get-a-quote-white.svg";
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    {
      url: "/services",
      title: "SERVICES",
      icon: "services.png",
      iconHover: "services-orange.png",
    },
    {
      url: "/equipment-hire",
      title: "EQUIPMENT",
      icon: "equipment-hire.png",
      iconHover: "equipment-hire-orange.png",
    },
    {
      url: "#contact",
      title: "GET A QUOTE",
      icon: "get-a-quote-white.svg",
      iconHover: "get-a-quote-orange.svg",
    },
    {
      url: "/about-us",
      title: "ABOUT US",
      icon: "about-us.png",
      iconHover: "about-us-orange.png",
    },
    {
      url: "/projects",
      title: "PROJECTS",
      icon: "projects.png",
      iconHover: "projects-orange.png",
    },
  ];

  //SERVICES | EQUIPMENT | GET QUOTE | ABOUT US | PROJECTS

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className='bottomMenu'>
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}
