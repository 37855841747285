// eslint-disable-next-line
import { transform } from "lodash";
import { mq } from "../../../../cssInJs";
// eslint-disable-next-line
import { backgroundImages, border } from "polished";

export default {
  quote__panel: mq({
    padding: 0,
    textAlign: "center",
    backgroundColor: "transparent",
    position: "relative",
    height: ["56px", "78px"],
    //border: '1px solid red',
    marginTop: "-2rem",
    overflow: "hidden",
    //backgroundPosition: 'top',
    //backgroundSize: '100% auto',
    //backgroundRepeat: 'no-repeat',
    //backgroundImage: ['url("/assets/UGI-QUOTE-PANEL-MOBILE.svg")', null, 'url("/assets/UGI-QUOTE-PANEL-DESKTOP.svg")']
  }),
  quote__panel__btn_con: mq({
    position: "absolute",
    //right: ['calc((100vw) * 200 / 515)',null,'53.2%'],
    right: ["calc((100vw) * 200 / 515)", null, "calc((100vw * 1010) / 1903)"],
    top: "54%",
    transform: "translateY(-50%)",
    //transform: 'translateX(calc(100vw - (100vw + 10)))'
    //transform: 'translateX(calc(100vw*-))'
    //left: '36.5%'
  }),
  quote__panel__bar: mq({
    //position: 'absolute',
    width: "100%",
    //bottom: '27px',
    lineHeight: 0,
    display: ["none", null, "block"],
    //height: '100%',
    //top: '0px'
  }),

  quote__panel__bar2: mq({
    //position: 'absolute',
    width: "100%",
    //bottom: '27px',
    lineHeight: 0,
    display: ["block", null, "none"],
    //height: '100%',
    //top: '0px'
  }),

  quote__panel__bar__d: {
    //isplay: 'inline-block',
    //width: '10px',
    //height: '100%',
    position: "absolute",
  },
  quote__panel__btn: {
    padding: "0.1rem 1rem",
    fontWeight: 700,
    color: "white",
    backgroundColor: "#F95427",
    display: "inline-block",
    transform: "skewX(40deg)",
    "&:hover, &:active, &:focus": {
      color: "white",
      backgroundColor: "#BC3E20",
    },
    "span": {
      transform: "skewX(-40deg)",
      display: "inline-block",
    },
  },

  quote__panel__bar__dummy: mq({
    //position: 'absolute',
    width: "100%",
    height: "100%",
    backgroundColor: "#434343",
    //top : '50%',
    display: ["block", null, null, null, null, "block"],
    marginTop: "-1px",
    //bottom : '0px'
  }),
};
