import { fb, validators } from "../../../lib/form";

export function getContactModel(data) {
  let serviceList = [
    "Horizontal Directional Drilling",
    "Underground Utility Installation",
    "Vacuum Excavation",
    "Trenching",
    "Equipment Hire",
    "Other (please specify)",
  ].map((x) => {
    return { name: x, value: x };
  });
  serviceList = [
    {
      name: "Please Select*",
      value: "",
    },
    ...serviceList,
  ];

  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Name", type: "text" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
    services: [
      data.services || "",
      [validators.Required()],
      { label: null, type: "select", options: serviceList },
    ],
    enquiry: [
      data.enquiry || "",
      [validators.Required()],
      { label: "Your Message", type: "textarea" },
    ],
    attachments: [
      data.attachments || "",
      [],
      { label: "Attachments", type: "file" },
    ],
  });
  return model;
}
