//import React from 'react';
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import Slider from "react-slick";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
// import style from "./style";

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const tileType = cmsUtils.payload(item, "TileType") || "1";
  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
      tileType={tileType}
      screenSize={screenSize}
    ></Tile>
  ));

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles__bsyc",
    "pageTiles tiles",
    "tileType__" + tileType,
    useSlider && "useSlider",
    item.cssClass || item.anchorName || ""
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  useEffect(() => {
    const checkTileSize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", checkTileSize);

    return () => {
      window.removeEventListener("resize", checkTileSize);
    };

    // eslint-disable-next-line
  }, []);

  if (useSlider && tileType === "2") {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <Slider {...settings}>{pageTileElts}</Slider>
      </div>
    );
  }

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const index = props.idx;
  const tileType = props.tileType;
  const screenSize = props.screenSize;

  return (
    <div className={`pageTile tile index__${index % 2 === 0 ? "odd" : "even"}`}>
      <SiteLink
        className='pageTile__content tile__content'
        to={disableLink ? "" : page.pageUrl}
      >
        <div
          className={`tile__bg__container ${"tileType__" + tileType}`}
          data-aos={
            tileType === "1" && screenSize >= 992
              ? index % 2 === 0
                ? "fade-up-left"
                : "fade-up-right"
              : ""
          }
        >
          <div className={`tile__bg ${"tileType__" + tileType}`}>
            <div
              className='tile__bg__inner'
              style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
            ></div>
          </div>

          <div
            className={`tile__middle__bg__mobile ${"tileType__" + tileType}`}
          >
            <img
              src={`/assets/tile_1_mobile.png`}
              alt='tile_1_mobile'
              width='100%'
            />
          </div>

          <div className={`tile__middle__bg__2 ${"tileType__" + tileType}`}>
            <img
              src={`/assets/${
                index % 2 === 0 ? "tile_2_1.png" : "tile_2_2.png"
              }`}
              alt='tile_2'
            />
          </div>
        </div>

        <div
          className={`tile__details__container ${"tileType__" + tileType}`}
          data-aos={
            tileType === "1" && screenSize >= 992
              ? index % 2 === 0
                ? "fade-up-right"
                : "fade-up-left"
              : ""
          }
        >
          <div
            className={`pageTile_details tile__details ${
              "tileType__" + tileType
            }`}
          >
            <div className={`pageTile__title ${"tileType__" + tileType}`}>
              {page.pageTitle}
            </div>
            <div className={`pageTile_desc ${"tileType__" + tileType}`}>
              {page.tileDesc || page.description}
            </div>
            <div className='pageTile_btn'> VIEW PAGE </div>
          </div>

          <div className={`tile__middle__bg ${"tileType__" + tileType}`}>
            <img
              src={`/assets/${
                index % 2 === 0 ? "tile_1_1.png" : "tile_1_2.png"
              }`}
              alt='tile__middle__bg'
            />
          </div>
        </div>

        {/* <div className={`tile__middle__bg ${"tileType__" + tileType}`}>
          <img
            src={`/assets/${index % 2 === 0 ? "tile_1.png" : "tile_2.png"}`}
          />
        </div> */}
      </SiteLink>
    </div>
  );
}
