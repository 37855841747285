import { css } from "@emotion/core";
import { mq, variables } from "../../cssInJs";

export default {
  top__bar: mq({
    //height: 30,
    //top : '82px',
    width: "100%",
    //position: 'fixed',
    // zIndex: "999",
    background: "#434343",
    backgroundColor: variables.colorCream,
    zIndex: 10,
    display: ["none", null, null, "flex"],
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.8rem",
    marginBottom: "0.8rem",
    "& > div": {},
  }),

  top__bar__sub__container: {
    // background: "white",
    position: "absolute",
    top: [44, null, 55],
    width: "100%",
    // zIndex: -1,
    left: 0,
    // height: "0px",
    transition: "all 0.5s ease",
    // opacity: 0,
    //paddingTop : '2rem',
    //paddingBottom : '1rem',
    //display: 'none',
    overflow: "hidden",
  },

  top__bar__sub: mq({
    //position : 'absolute',
    display: "flex",
    flexDirection: "row",
    //maxWidth: '700px',
    //margin: 'auto',
    flexWrap: "wrap",
    marginLeft: "-0.5rem",
    marginRight: "-0.5rem",
    justifyContent: "center",
    //top: [43, null, 32],
    //left: 0,
    //width : 'max-content',
    //gap : '0.3rem',
    //paddingTop: '3px',
    //overflow: 'hidden',
    //height: 0,
    //opacity: 0,
    //paddingTop : 0
  }),

  top__bar__sub__item: mq({
    fontSize: ["11px", null, null, null, "13px"],
    flex: "1 0 20%",
    maxWidth: "20%",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    ".top__bar__sub__item__title": {
      marginTop: "0.5rem",
      color: variables.fontColor,
    },
  }),

  // top__bar__background : mq({
  //   background: 'white',
  //   position : 'fixed',
  //   top: [44, null, 56],
  //   width : '100%',
  //   zIndex: 1,
  //   height: '0px',
  //   transition : 'opacity .3s ease',
  //   opacity: 0,
  //   paddingTop : '1rem',
  //   paddingBottom : '1rem',
  //   '&.services__menu' : mq({
  //     //height: ['100px', null, null, null, '120px'],
  //     height: 'auto',
  //     opacity: '1',
  //   })
  // }),

  top__bar__item: mq({
    fontSize: ["0.76rem", null, null, null, "0.8rem"],
    //fontFamily: variables.familyHeader,
    lineHeight: 1,
    fontWeight: 700,
    //color : variables.fontColor,
    //position : 'relative',
    //paddingTop : '1rem',
    //paddingBottom : '1rem',
    "svg": {
      width: "30px",
      height: "30px",
      transition: "all 0.5s ease",
    },
    "&:hover": {
      // '.top__bar__sub' : mq({
      //   transition : 'opacity .4s ease',
      //   height: 'auto',
      //   opacity: '1',
      //   paddingTop : ['0.5rem',null,null,null,'1rem']
      // })
      ".top__bar__sub__container.open__sub": {
        //display: 'block',
        height: "auto",
        opacity: "1",
        //paddingTop : '2rem',
        //paddingBottom : '1rem',
      },
      "svg": {
        transform: "rotate(180deg)",
      },
    },
    //paddingLeft: '0.5rem',
    //paddingRight: '0.5rem',
    //letterSpacing: '3px',
    //borderLeft: '1px solid red',
    // '&.bar' : {
    //   paddingLeft: '0.5rem',
    //   paddingRight: '0.5rem',
    //   textAlign: 'center'
    // },
    "a": {
      color: "white",
      "&:hover": {
        color: "#F95427",
        textDecoration: "none",
      },
    },
    "a.btn.btn-primary": mq({
      fontSize: ["0.7rem", null, null, null, "0.7rem"],
      //borderRadius: 0,
      paddingTop: "12px",
      paddingBottom: "13px",
      lineHeight: 1,
      //backgroundColor : "#2D2D3C",
      //borderColor: "#2D2D3C",
      //color : 'white',
      "&:hover": {
        //backgroundColor : "#2D2D3C",
        //borderColor: "#2D2D3C",
      },
    }),
  }),

  top__bar__scrolled: {},

  top: css(
    mq({
      //height: [44, null, 80],
      height: [44, null, 134],
      background: "#434343",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
      flexDirection: "column",
    })
  ),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(
    mq({
      display: ["block", null, null, "block"],
    })
  ),
  logoImg: css(
    mq({
      height: [42, null, 60],
      marginTop: [0, null, "0.7rem"],
      marginBottom: [0, null, "0.7rem"],
      "&.desktop": mq({
        display: ["none", null, "block"],
      }),
      "&.mobile": mq({
        display: ["block", null, "none"],
      }),
    })
  ),
  logoScrolled: css(mq({})),

  svgbar: mq({
    position: "fixed",
    width: "100%",
    zIndex: 997,
    top: ["10px", null, "134px"],
    display: ["none", null, null, "block"],
    lineHeight: 0,
  }),
};
