/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
import { ReactComponent as YourSvg } from "./UGI-NAVIGATION-DESKTOP-V2.svg";
import { MdArrowDropUp } from "react-icons/md";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  // eslint-disable-next-line
  const [openSub, setOpenSub] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          <img
            css={style.logoImg}
            className='desktop'
            src='/assets/logos/logo.png'
            alt='logo'
          />
          <img
            css={style.logoImg}
            className='mobile'
            src='/assets/logos/logo2.png'
            alt='logo'
          />
        </SiteLink>
        <div css={[style.top__bar, scrolled && style.top__bar__scrolled]}>
          {/* <div css={style.top__bar__item} className='ddddd'>
            <SiteLink to='/'> Home </SiteLink>
          </div> */}

          <div css={style.top__bar__item}>
            <SiteLink to='/about-us'> About Us </SiteLink>
          </div>

          <div
            css={style.top__bar__item}
            className='top__bar__dropdown'
            onMouseEnter={() => {
              setOpenSub(true);
            }}
            onMouseLeave={() => {
              setOpenSub(false);
            }}
          >
            <SiteLink to='/services'>
              {" "}
              Services <MdArrowDropUp />{" "}
            </SiteLink>
            <div
              css={style.top__bar__sub__container}
              className={`top__bar__sub__container `}
            >
              <div className='top__bar__sub__container__wrap'>
                <div
                  style={{
                    margin: "auto",
                    maxWidth: "1175px",
                    paddingTop: "2rem",
                    paddingBottom: "1rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <div css={style.top__bar__sub} className='top__bar__sub'>
                    <div
                      css={style.top__bar__sub__item}
                      className='top__bar__sub__item'
                    >
                      <SiteLink
                        to='/horizontal-directional-drilling'
                        onClick={() => {
                          setOpenSub(false);
                        }}
                      >
                        <div>
                          <img
                            width='100%'
                            alt='top nav'
                            src='/sites/ugi/media/images/top-nav---images/horizontal-directional-drilling-nav-832x468.jpg'
                          />
                        </div>
                        <div className='top__bar__sub__item__title'>
                          Horizontal Directional Drilling
                        </div>
                      </SiteLink>
                    </div>

                    <div
                      css={style.top__bar__sub__item}
                      className='top__bar__sub__item'
                    >
                      <SiteLink
                        to='/underground-utility-service-installations'
                        onClick={() => {
                          setOpenSub(false);
                        }}
                      >
                        <div>
                          <img
                            width='100%'
                            alt='top nav'
                            src='/sites/ugi/media/images/top-nav---images/underground-utility-service-installations.jpg'
                          />
                        </div>
                        <div className='top__bar__sub__item__title'>
                          Underground Utility Installation
                        </div>
                      </SiteLink>
                    </div>

                    <div
                      css={style.top__bar__sub__item}
                      className='top__bar__sub__item'
                    >
                      <SiteLink
                        to='/vacuum-excavation'
                        onClick={() => {
                          setOpenSub(false);
                        }}
                      >
                        <div>
                          <img
                            width='100%'
                            alt='top nav'
                            src='/sites/ugi/media/images/top-nav---images/vacuum-excavation.jpg'
                          />
                        </div>
                        <div className='top__bar__sub__item__title'>
                          Vacuum Excavation
                        </div>
                      </SiteLink>
                    </div>

                    <div
                      css={style.top__bar__sub__item}
                      className='top__bar__sub__item'
                    >
                      <SiteLink
                        to='/trenching-contractors'
                        onClick={() => {
                          setOpenSub(false);
                        }}
                      >
                        <div>
                          <img
                            width='100%'
                            alt='top nav'
                            src='/sites/ugi/media/images/top-nav---images/trenching-contractors.jpg'
                          />
                        </div>
                        <div className='top__bar__sub__item__title'>
                          Trenching Contractors
                        </div>
                      </SiteLink>
                    </div>

                    <div
                      css={style.top__bar__sub__item}
                      className='top__bar__sub__item'
                    >
                      <SiteLink
                        to='/grade-control'
                        onClick={() => {
                          setOpenSub(false);
                        }}
                      >
                        <div>
                          <img
                            width='100%'
                            alt='top nav'
                            src='/sites/ugi/media/images/Top-Nav---Images/Grade-Controle.jpg'
                          />
                        </div>
                        <div className='top__bar__sub__item__title'>
                          Grade Control
                        </div>
                      </SiteLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div css={style.top__bar__item}>
            <SiteLink to='/equipment-hire'> Equipment Hire </SiteLink>
          </div>

          <div css={style.top__bar__item}>
            <SiteLink to='/projects'> Projects </SiteLink>
          </div>

          <div css={style.top__bar__item}>
            <SiteLink to='/contact-us'> Contact Us </SiteLink>
          </div>
        </div>
        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>

      <div css={style.svgbar}>
        <YourSvg />
      </div>

      {/* <div css={style.svgbar}>
          <YourSvg />
        </div> */}

      {/* <div css={style.top__bar__background} ref={ref}>
        <div style={{ margin: "auto", maxWidth: "1175px" }}>
          <div css={style.top__bar__sub} className="top__bar__sub">
            {subCategories.map((item, index) => {
              return (
                <div
                  key={index}
                  css={style.top__bar__sub__item}
                  className="top__bar__sub__item"
                >
                  <SiteLink to="/">
                    <div>
                      <img width="100%" src={item.image} />
                    </div>
                    <div className="top__bar__sub__item__title">{item.title}</div>
                  </SiteLink>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </Fragment>
  );
}

// {
//   /* <div css={style.top__bar__item}>
//             <SiteLink className="btn btn-primary" to="/#booknow">
//               {" "}
//               <strong>Get a Quote</strong>{" "}
//             </SiteLink>
//           </div> */
// }
