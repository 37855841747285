/** @jsx jsx */
import { jsx } from "@emotion/core";
//import cmsUtils from "../../cmsUtils";
import utils from "../../../../utils";
import style from "./quotePanelStyle";
import { SiteLink } from "components";
import { ReactComponent as QuoteDesk } from "./UGI-QUOTE-PANEL-DESKTOP-v2.svg";
import { ReactComponent as QuoteMobile } from "./UGI-QUOTE-PANEL-MOBILE-V2.svg";

export function QuotePanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "contentPanel",
    "quote__panel",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      css={style.quote__panel}
      className={cssClass}
      id={item.anchorName}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
    >
      <div css={style.quote__panel__bar} className='quote__panel__bar'>
        <QuoteDesk />
      </div>

      <div css={style.quote__panel__bar2} className='quote__panel__bar2'>
        <QuoteMobile />
      </div>

      <div
        css={style.quote__panel__bar__dummy}
        className='quote__panel__bar__dummy'
      ></div>

      <div css={style.quote__panel__btn_con} className='quote__panel__btn_con'>
        <SiteLink
          to='#contact'
          css={style.quote__panel__btn}
          className='quote__panel__btn'
        >
          <span> GET A QUOTE </span>
        </SiteLink>
      </div>
    </div>
  );
}
