import utils from "../../../utils";
import cmsUtils from "../../../cms/utils/cmsUtils";
import { IoIosStar } from "react-icons/io";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";

export function TestimonialItem(props) {
  const item = props.item;
  //const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "testimonialItem",
    item.cssClass || item.anchorName || ""
  );

  const startRatingColor = "#fbbb07";

  const title = cmsUtils.payload(item, "Title");
  const desc = cmsUtils.payload(item, "Desc");
  const bottom = cmsUtils.payload(item, "BottomText");
  const ratingValue = parseInt(cmsUtils.payload(item, "RatingStar"));

  var rating = [];

  for (var i = 0; i < ratingValue; i++) {
    rating.push(<IoIosStar key={i} style={{ color: startRatingColor }} />);
  }

  return (
    // data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
    <div css={style.testimonialItem} className={cssClass}>
      <div css={style.testimonialItem_star} className='rating'>
        {rating}
      </div>
      <h4 css={style.testimonialItem_title} className='title'>
        {title}
      </h4>
      {/* style={{ flexBasis: props.desH + "px" }} */}
      <div css={style.testimonialItem_desc} className='desc'>
        {desc}
      </div>
      <h5 css={style.testimonialItem_bottom} className='bottom'>
        {bottom}
      </h5>
    </div>
  );
}