import { css } from "@emotion/core";
import { mq, variables } from "../../../cssInJs";

export default {
  testimonialItem: css(
    mq({
      maxWidth: "300px",
      backgroundColor: "#fff",
      textAlign: "left",
      color: "#000",
      padding: "1.5rem",
      //height: '100%',
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      margin: "auto",
      height: ["400px", "430px"],
      borderBottom: "6px solid " + variables.colorOrange,
    })
  ),

  testimonialItem_title: css(
    mq({
      marginBottom: "1rem",
      fontSize: ["1.2rem", "0.92rem"],
      lineHeight: "1.3",
    })
  ),
  testimonialItem_star: css(
    mq({
      marginBottom: ".8rem",
    })
  ),

  testimonialItem_desc: css(
    mq({
      // flex: "1 1 auto",
      fontSize: ["1rem", "0.73rem"],
      lineHeight: "1.3",
      marginBottom: "1rem",
      //flexGrow: '1',
      //flexShrink: '1'
    })
  ),

  testimonialItem_bottom: css(
    mq({
      fontSize: ["1rem", "0.69rem"],
    })
  ),
};
