import React from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const fileAttachment = props.fileAttachment || "false";
  const mailchimpApiKey = props.mailchimpApiKey;
  const mailchimpAudienceKey = props.mailchimpAudienceKey;

  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [mailChimpError, setMailChimpError] = React.useState();
  const post = usePost();

  const mailChimpErrorMemo = React.useMemo(() => {
    return mailChimpError;
  }, [mailChimpError]);

  // function onSubmit(e) {
  //   setMailChimpError(null);
  //   form.validateForm(e, () => {
  //     const value = {
  //       ...form.getValue(),
  //        emailReceiver: emailReceiver,
  //        mailchimpApiKey : mailchimpApiKey,
  //        mailchimpAudienceKey : mailchimpAudienceKey
  //     };
  //     var files = form.getFiles();
  //     var formData = new FormData();
  //     formData.append('jsonData', JSON.stringify(value))
  //     files.forEach(file => {
  //       (file.files || []).forEach(uploadFile => {
  //         formData.append(file.name, uploadFile)
  //       })
  //     });
  //     //post.send(env.apiBase + "/api/contact/send", value);
  //     post.sendFormData(env.apiBase + "/api/contact/send", formData);
  //     setSendingStatus('pending')
  //   })

  //   console.log('submitting')
  // }

  function onSubmit(e) {
    setMailChimpError(null);
    form.validateForm(e, () => {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(env.reCaptchaSiteKey, { action: "submit" })
          .then(function (token) {
            const value = {
              ...form.getValue(),
              emailReceiver: emailReceiver,
              mailchimpApiKey: mailchimpApiKey,
              mailchimpAudienceKey: mailchimpAudienceKey,
              grecaptchaToken: token,
            };

            var files = form.getFiles();
            var formData = new FormData();
            formData.append("jsonData", JSON.stringify(value));
            files.forEach((file) => {
              (file.files || []).forEach((uploadFile) => {
                formData.append(file.name, uploadFile);
              });
            });
            post.sendFormData(env.apiBase + "/api/contact/send", formData);
            setSendingStatus("pending");
          });
      });
    });
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);

    if (
      post.response.results &&
      post.response.results.MailChimpError &&
      post.response.results.MailChimpError !== ""
    ) {
      setMailChimpError(
        "Mail Chimp Error : " + post.response.results.MailChimpError
      );
    }
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className='contactForm'>
      <div className='form-row'>
        <div className='col-md-4 mb-2'>{render("name")}</div>
        <div className='col-md-4 mb-2'>{render("phone")}</div>
        <div className='col-md-4 mb-2'>{render("email")}</div>
      </div>
      <div className='form-row'>
        <label style={{ color: "#FFF" }}>
          Please select the service/s you require for your project*
        </label>
        <div className='col-md-12 mb-2'>{render("services")}</div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>{render("enquiry")}</div>
      </div>

      {fileAttachment === "true" && (
        <>
          <div className='form-row helptext'>
            <label style={{ color: "#FFF" }}>
              We accept a variety of file types: PNG, JPEG, PDF, Word documents
              and spreadsheets etc.
            </label>
          </div>
          <div className='form-row'>
            <div className='col-md-12 mb-2'> {render("attachments")} </div>
          </div>
        </>
      )}

      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Send Message
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage='Please review the errors.'
            />
            <ErrorMessage errors={post.errors} />
            <ErrorMessage errors={mailChimpErrorMemo} />
            {post.done() && (
              <span
                style={{
                  marginLeft: "1.5rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Successfully sent!
              </span>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
